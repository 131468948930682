import React from "react";
import Show from "./Show";
import { Style } from "react-style-tag";

export default function SingleShow(props) {
  return (
    <>
      <Style>{`
        .logo-link {
            pointer-events: none;
            margin-inline-end: auto;
        }
        header nav, .search-button {
            display: none !important;
        }
        `}</Style>
      <Show {...props} single />
    </>
  );
}
