export const constantData = {
  contactTypes: [
    {
      name: "יחץ מקור",
      contacts: [
        {
          name: "אלכס",
          role: null,
          email: "alex@minkovsky-media.com",
          phone: null,
        },
        {
          name: "דבורי",
          role: null,
          email: "dvory@mfu.co.il",
          phone: null,
        },
        {
          name: "רון",
          role: null,
          email: "Ron.Weinberg@hot.net.il",
          phone: null,
        },
      ],
    },
    {
      name: "יחץ רכש",
      contacts: [
        {
          name: "נמרוד",
          role: null,
          email: "Nimrod.Nave@hot.net.il",
          phone: null,
        },
      ],
    },
  ],
  socialLinks: {
    facebook: "https://www.facebook.com/hot.net.il",
    instagram: "https://www.instagram.com/hot_il/",
    twitter: "https://twitter.com/hotmobileisrael?lang=he",
    youtube: "https://www.youtube.com/user/hot",
  },
  logos: [
    //    מחכה לחן לי...
  ],
};
