import { BrowserRouter as HashRouter, Switch, Route, Redirect } from "react-router-dom";

import "./scss/main.scss";

import Header from "./components/Header";
import Slider from "./components/Slider";
import Series from "./components/Series";
import Show from "./components/Show";
import SingleShow from "./components/SingleShow";
import Archive from "./components/Archive";
import Contact from "./components/Contact";
import Search from "./components/Search";
import Airing from "./components/Airing";
import Summary from "./components/Summary";
import { useState, useEffect } from "react";

import { ReleaseContext } from "./context/ReleaseContext";
import { getRelease } from "./services/release.service";

function App() {
  const releaseId = window.releaseId;

  const [release, setRelease] = useState();

  useEffect(() => {
    if (releaseId) {
      console.log("fetching release!");
      getRelease(releaseId)
        .then((data) => {
          console.log("release data", data);
          setRelease(data);
        });
    } else {
      console.error('window.releaseId not found')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!releaseId) return "window.releaseId not found";

  return (
    <div id="container">
      <ReleaseContext.Provider value={release}>
        <HashRouter basename="/#">
          <Header></Header>

          <Switch>
            <Route path="/" component={Slider} exact />
            <Route path="/premieres" component={Series} />
            <Route path="/search" component={Search} />
            <Route path="/summary" component={Summary} />
            <Route path="/airing" component={Airing} />
            <Route path="/contact" component={Contact} />
            <Route path="/archive" component={Archive} />
            <Route path="/show/:showId/:page/" component={Show} />
            <Route path="/show/:showId" component={Show} />
            <Route path="/single/:showId/:page/" component={SingleShow} />
            <Route path="/single/:showId" component={SingleShow} />
            <Route path="/show/"><Redirect to="/" /></Route>
            <Route path="*"><div className="wrap"><h4>הדף לא נמצא</h4></div></Route>
          </Switch>
        </HashRouter>
      </ReleaseContext.Provider>
    </div>
  );
}

export default App;
