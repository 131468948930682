import React from "react";
import { getLicenseType } from "../fields/licenseType";

// possible classes: gradient, red, pink, black
const licenseTypeClasses = {
  מקור: "pink",
  רכש: "red",
};

export default function SideLabel(props) {
  const { licenseType } = props; // returns an id

  const label = getLicenseType(licenseType);
  const classes = licenseTypeClasses[label] || "";

  return !label ? (
    ""
  ) : (
    <div className={`side-label ${classes}`}>
      <div className="inside">{label}</div>
    </div>
  );
}
