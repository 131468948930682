import React from "react";
import { constantData } from "../data/constantData";
import ContactRow from "./sub-components/ContactRow";

export default function Contact() {
  return (
    <div className="contact-page">
      {constantData.contactTypes.map((contactType, key) => (
        <div className="contact-type" key={key}>
          {contactType?.contacts && (
            <>
              {/* <hr /> */}
              {contactType.name && <h5>{contactType.name}</h5>}
              <div className="contacts">
                {contactType.contacts.map((contact, key) => (
                  <ContactRow contact={contact} key={key} />
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
