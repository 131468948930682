import React, { useEffect, useState } from "react";
import Embed from "react-embed";
import { Link } from "react-router-dom";
import { getShow } from "../services/release.service";
import Contact from "./sub-components/ContactRow";

export default function Show(props) {
  const { showId, page } = props.match.params;
  console.log("props.match.params", props.match.params);

  const [show, setShow] = useState();

  const preLink = props.single ? "single" : "show";

  useEffect(() => {
    getShow(showId).then((data) => {
      console.log("show", data);
      setShow(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrap">
      <section className="show">
        {!show ? (
          <h5>טוען...</h5>
        ) : !show._id ? (
          <h5>תכנית לא נמצאה</h5>
        ) : (
          <div className="inside">
            <nav>
              <ul>
                <li className={!page ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/`}>תקציר</Link>
                </li>
                <li className={page === "play" ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/play`}>לצפייה</Link>
                </li>
                <li className={page === "pictures" ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/pictures`}>תמונות</Link>
                </li>
                <li className={page === "festivals" ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/festivals`}>פסטיבלים</Link>
                </li>
                <li className={page === "links" ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/links`}>קישורים</Link>
                </li>
                <li className={page === "reviews" ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/reviews`}>ביקורות</Link>
                </li>
                <li className={page === "time" ? "active" : ""}>
                  <Link to={`/${preLink}/${showId}/time`}>שעות שידור</Link>
                </li>
              </ul>
            </nav>
            <hr className="vertical" />
            <div className="content">
              {!page ? (
                <div className="summary">
                  <img
                    src={show?.releaseData?.mainImage[0].src}
                    className="main-thumbnail"
                    alt={show?.releaseData?.mainImage[0].title}
                  />
                  <h2>
                    {show?.releaseData?.title}
                    {show?.releaseData?.title_english && (
                      <>
                        <br />
                        {show?.releaseData?.title_english}
                      </>
                    )}
                  </h2>

                  {show?.releaseData?.subtitle && (
                    <h3 className="subtitle">{show?.releaseData?.subtitle}</h3>
                  )}
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: show?.preview?.releaseData?.content,
                    }}
                  ></div>
                  {(show?.releaseData?.broadcastTimesDescription ||
                    show?.releaseData?.logos) && <hr />}
                  <div className="extra-data">
                    <div
                      className="broadcast-times-description"
                      dangerouslySetInnerHTML={{
                        __html: show?.releaseData?.broadcastTimesDescription,
                      }}
                    ></div>
                    {show?.releaseData?.logos && (
                      <div className="logos">
                        {show?.releaseData?.logos.map((logo, key) => (
                          <div className="logo" key={key}>
                            <img src={logo.src} alt={logo.title} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {show?.releaseData?.contacts && (
                    <>
                      <hr />
                      <div className="contacts">
                        {show?.releaseData?.contacts.map((contact, key) => (
                          <Contact contact={contact} key={key} />
                        ))}
                      </div>
                    </>
                  )}

                  <hr />
                </div>
              ) : page === "play" ? (
                <div className="play">
                  {!show?.releaseData ||
                  !show?.releaseData?.videos ||
                  !show?.releaseData?.videos.length ? (
                    <h5>אין סרטונים</h5>
                  ) : (
                    show?.releaseData?.videos.map((video, key) => (
                      <div key={key}>
                        <h3>{video.title}</h3>
                        <div className="video-wrapper">
                          <Embed url={video.link} title={video.title}></Embed>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "pictures" ? (
                <div className="pictures-part">
                  {!show?.releaseData ||
                  !show?.releaseData?.imageGallery ||
                  !show?.releaseData?.imageGallery.length ? (
                    <h5>אין תמונות</h5>
                  ) : (
                    <>
                      <div class="pictures">
                        {show?.releaseData?.imageGallery.map((img, key) => (
                          <div className="picture" key={key}>
                            <div className="inside">
                              <img src={img.src} alt={img.title} />
                              <div className="links">
                                <a
                                  href={img.src}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  פתח
                                </a>
                                <a href={img.src} download={img.title}>
                                  הורד
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <p>{show?.releaseData?.galleryCredit}</p>
                    </>
                  )}
                </div>
              ) : page === "festivals" ? (
                <div className="festivals">
                  {!show?.releaseData?.festivals ? (
                    <h5>אין מידע</h5>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: show?.releaseData?.festivals,
                      }}
                    ></div>
                  )}
                </div>
              ) : page === "links" ? (
                <div className="links">
                  {!show?.releaseData?.links ? (
                    <h5>אין קישורים</h5>
                  ) : (
                    show?.releaseData?.links?.map((link, key) => (
                      <div className="link" key={key}>
                        <a href={link.link} target="_blank" rel="noreferrer">
                          {link.title}
                        </a>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "reviews" ? (
                <div className="reviews">
                  {!show?.releaseData?.reviews ? (
                    <h5>אין ביקורות</h5>
                  ) : (
                    show?.releaseData?.reviews.map((review, key) => (
                      <div className="review" key={key}>
                        <a href={review.link} target="_blank" rel="noreferrer">
                          {review.title}
                        </a>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "time" ? (
                <div className="time">
                  {!show?.preview?.releaseData?.broadcastTimes ? (
                    <h5>אין שעות שידור</h5>
                  ) : (
                    <table className="pretty-table">
                      <thead>
                        <tr>
                          <td>תוכנית</td>
                          <td>ימים</td>
                          <td>תאריך</td>
                          <td>שעות</td>
                        </tr>
                      </thead>
                      <tbody>
                        {show?.preview?.releaseData?.broadcastTimes.map(
                          (broadcastTime, key) => (
                            <tr key={key}>
                              <td>{show?.preview?.releaseData?.title}</td>
                              <td>{broadcastTime.dayText}</td>
                              <td>{broadcastTime.dateText}</td>
                              <td>{broadcastTime.hourText}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : page === "full" ? (
                <div className="full">המידע של הפרקים המלאים חסר ב־API</div>
              ) : (
                <h2>עמוד לא נמצא</h2>
              )}
              {/* <pre dir="ltr">{JSON.stringify(show, null, 4)}</pre> */}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
