import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReleaseContext } from "../context/ReleaseContext";
import AiringPlayButton from "./sub-components/AiringPlayButton";

export default function Airing() {
  // eslint-disable-next-line no-unused-vars
  const release = useContext(ReleaseContext);
  return (
    <div className="airing-page">
      <div className="wrap">
        <div className="airing-table">
          <div className="header row">
            <div>תכנית</div>
            <div>ימים</div>
            <div>תאריך</div>
            <div>שעות</div>
            <div>תמונות</div>
            <div>לצפייה</div>
          </div>
          {release?.releaseData.shows.map((show, key) => (
            <div className="row show" key={key}>
              <Link to={`/show/${show?._id}`} className="dayText">
                {show?.releaseData?.broadcastTimes?.map(
                  (broadcastTime, key) => (
                    <div key={key}>
                      <div>{key === 0 ? <strong>{show?.releaseData?.title}</strong> : 'שידור נוסף'}</div>
                      {show?.releaseData?.broadcastTimes.length - 1 !== key && (
                        <hr />
                      )}
                    </div>
                  )
                )}
            </Link>
              <div className="dayText">
                {show?.releaseData?.broadcastTimes?.map(
                  (broadcastTime, key) => (
                    <div key={key}>
                      <div>{broadcastTime?.dayText}</div>
                      {show?.releaseData?.broadcastTimes.length - 1 !== key && (
                        <hr />
                      )}
                    </div>
                  )
                )}
              </div>
              <div className="dateText">
                {show?.releaseData?.broadcastTimes?.map(
                  (broadcastTime, key) => (
                    <div key={key}>
                      <div>{broadcastTime?.dateText}</div>
                      {show?.releaseData?.broadcastTimes.length - 1 !== key && (
                        <hr />
                      )}
                    </div>
                  )
                )}
              </div>
              <div className="hourText">
                {show?.releaseData?.broadcastTimes?.map(
                  (broadcastTime, key) => (
                    <div key={key}>
                      <div>{broadcastTime?.hourText}</div>
                      {show?.releaseData?.broadcastTimes.length - 1 !== key && (
                        <hr />
                      )}
                    </div>
                  )
                )}
              </div>
              <div>
              <Link to={`/show/${show?._id}/pictures`}><img
                  src={show?.releaseData?.mainImage?.[0].src}
                  alt={show?.releaseData?.mainImage?.[0].title}
                /></Link>
              </div>
              <div>
                {show.releaseData?.videos && <AiringPlayButton video={show.releaseData?.videos[0]} />}
              </div>
              {/* <div>{JSON.stringify(show)}</div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
