import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReleaseContext } from "../context/ReleaseContext.js";
import { getShowType } from "../fields/showType.js";
import SideLabel from "./SideLabel.js";

export default function Series() {
  const release = useContext(ReleaseContext);

  return (
    <section className="series">
      <h2 className="section-title">תכניות בערוץ HOT 8</h2>
      <div className="inside">
        {release?.releaseData?.shows.map((show, key) => (
          <div className="serie" key={key}>
            <Link to={`/show/${show._id}`}>
              <div className="img-wrap">
                <SideLabel licenseType={show?.releaseData?.licenseType} />
                <img
                  src={show?.releaseData?.mainImage[0].src}
                  alt={show.allText}
                />
              </div>
              <div className="data">
                <h3>{show?.releaseData?.title}</h3>
                {show?.releaseData?.subtitle && (
                  <h4>{show?.releaseData?.subtitle}</h4>
                )}
                {show?.releaseData?.showType && (
                  <p className="show-type">
                    {getShowType(show?.releaseData?.showType)}
                  </p>
                )}
                {/* <span className="special-text">{show?.releaseData?.specialText}</span> */}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}
