import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { constantData } from "../data/constantData";
import { ReleaseContext } from "../context/ReleaseContext";

export default function Header() {
  // eslint-disable-next-line no-unused-vars
  const release = useContext(ReleaseContext);

  let title1 = (release?.releaseData?.title || "").split(" ");
  let title2 = title1.pop();
  title1 = title1.join(" ");

  return (
    <header id="header" className="header">
      <NavLink to="/" className="logo-link">
        <section className="logo">
          <h1>
            <img src="/favicon.png" alt="Hot" />
          </h1>
        </section>
      </NavLink>
      <nav>
        <ul>
          <li>
            <NavLink to="/premieres" activeClassName="is-active">
              בכורות אוגוסט
            </NavLink>
          </li>
          <li>
            <NavLink to="/summary" activeClassName="is-active">
              תקצירי החודש
            </NavLink>
          </li>
          <li>
            <NavLink to="/airing" activeClassName="is-active">
              לוח שידורים
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeClassName="is-active">
              צור קשר
            </NavLink>
          </li>
          <li>
            <NavLink to="/archive" activeClassName="is-active">
              ארכיון
            </NavLink>
          </li>
          <li>
            <NavLink to="/search" activeClassName="is-active">
              חיפוש
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="left-side">
        <div className="social-icons">
          <a
            href={constantData.socialLinks.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook" alt="facebook"></i>
          </a>
          <a
            href={constantData.socialLinks.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram" alt="instagram"></i>
          </a>
          <a
            href={constantData.socialLinks.youtube}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube" alt="twitter"></i>
          </a>
          <a
            href={constantData.socialLinks.twitter}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter" alt="twitter"></i>
          </a>
        </div>
        <div className="name">
          <div>
            <span className="month">{title1}</span>{" "}
            <span className="year">{title2}</span>
          </div>
        </div>
        {/* <NavLink to="/search" activeClassName="is-active"><div className="search-button"></div></NavLink> */}
      </div>
    </header>
  );
}
