import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReleaseContext } from "../context/ReleaseContext";
import AiringPlayButton from "./sub-components/AiringPlayButton";

export default function Summary() {
  // eslint-disable-next-line no-unused-vars
  const release = useContext(ReleaseContext);
  return (
    <div className="wrap summary-page">
      {release?.releaseData?.shows.map((show, key) => (
        <article key={key} className="show">
          <div className="header-wrap">
            <Link to={`/show/${show._id}`}>
              <header>
                <h2>
                  {show?.releaseData?.title}
                  {show?.releaseData?.title_english && (
                    <>
                      <br />
                      {show?.releaseData?.title_english}
                    </>
                  )}
                </h2>
                <h3 className="subtitle">{show?.releaseData?.subtitle}</h3>
                <hr />
                <div className="broadcast-times-description"
                  dangerouslySetInnerHTML={{
                    __html: show?.releaseData?.broadcastTimesDescription,
                  }}
                ></div>
              </header>
            </Link>
            {show?.releaseData?.mainImage[0]?.src && (
              <div className="video">
                {show?.releaseData?.videos && <AiringPlayButton video={show?.releaseData?.videos[0]} />}
                <img
                  src={show?.releaseData?.mainImage[0]?.src}
                  alt={show?.releaseData?.mainImage[0]?.title}
                />
              </div>
            )}
          </div>
          <div className="content">
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: show?.preview?.releaseData?.content,
              }}
            ></div>
          </div>
        </article>
      ))}
    </div>
  );
}
