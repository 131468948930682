export function getRelease(releaseId) {
  // 615b09db25a07eb7191332c0
  // זאת דוגמא ללוח חודשי -
  return fetch(`https://api.megaphone-test.com/release/${releaseId}/data`)
  .then((response) => response.json());
}
export function getShow(showId) {
  // 60343c36ddb26d52b626967f
  // וזאת דוגמא לתוכנית (לקחתי אותה מ-״כאן״ כי לא ראיתי שיש ב-״הוט״ תוכניות עם מספיק דטה)
  return fetch(`https://api.megaphone-test.com/release/${showId}/data`)
  .then((response) => response.json());
}
export function searchFor(searchTerm) {
  // חיפוש מחזיר מערך של תוכניות:
  return fetch(
    `https://api.megaphone-test.com/organization/5df35d71c8975a58efc6a1aa/releases?releaseType=612db12b8ad9893533b85bae&isInEdit=false&searchTerm=${searchTerm}`
  )
  .then((response) => response.json());
}
export function getAllReleases() {
  // ואתה יכול להשתמש ככה בחיפוש כדי לקבל את כל הלוחות החודשיים:
  return fetch(
    `https://api.megaphone-test.com/organization/5df35d71c8975a58efc6a1aa/releases?releaseType=612db1618ad9893533b85baf&isInEdit=false&searchTerm=`
  )
  .then((response) => response.json());
}
