import React, { useContext } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
// import {
//   Navigation,
//   Pagination,
//   Scrollbar,
//   A11y,
//   Autoplay,
//   Keyboard,
// } from "swiper";
import { ReleaseContext } from "../context/ReleaseContext.js";
import { Link } from "react-router-dom";

export default function Slider() {
  const release = useContext(ReleaseContext);

  return (
    <section className="slider">
      <div className="inside">
        <Swiper
          // spaceBetween={50}
          navigation
          loop
          autoplay
          pagination
          // pagination={{ clickable: true, type: "bullets" }}
          // pagination={{ clickable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {release?.releaseData?.shows.map((show, key) => (
            <SwiperSlide key={key}>
              <Link to={`/show/${show._id}`}>
                <img
                  src={show?.releaseData?.mainImage[0]?.src}
                  alt={show?.releaseData?.mainImage[0]?.title}
                />
                <div className="release-details">
                  <h3>{show?.releaseData?.title}</h3>
                  <h4>{show?.releaseData?.subtitle}</h4>
                  {show?.releaseData?.broadcastTimesDescription && (
                    <>
                      <hr />
                      <div
                        className="broadcast-times-description"
                        dangerouslySetInnerHTML={{
                          __html: show?.releaseData?.broadcastTimesDescription,
                        }}
                      ></div>
                    </>
                  )}
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
